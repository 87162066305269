* {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
}

.flex-2 {
  flex: 2;
}
.text-center {
  text-align: center;
}
.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.border-debug {
  border: 1px solid red;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 5px;
}
.gap-2 {
  gap: 10px;
}

.gap-3 {
  gap: 20px;
}

.align-items-center {
  align-items: center;
}

.p-2 {
  padding: 10px;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-1 {
  padding-right: 10px;
}

.p-b-1 {
  padding-bottom: 10px;
}

.primary {
  color: $primary;
}

.white {
  color: $white;
}

.mt-1 {
  margin-top: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
}

.align-self-center {
  align-self: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.w-100 {
  width: 100%;
}

.mt-2 {
  margin-top: 20px;
}

.m-2 {
  margin: 10px;
}

.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100%;
}

.align-selft-left {
  align-self: left;
}

.flex-none {
  flex: none;
}

.rounded {
  border-radius: 50%;
}

.h-100 {
  height: 100%;
}

.color {
  color: $white;
}

.border-b {
  border-bottom: 1px solid $border-color;
}

.fz-14 {
  font-size: 14px;
}

.fz-12 {
  font-size: 12px;
}
