body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sans-serif;
  color: $white;
}

textarea {
  font-family: $sans-serif !important;
}

a {
  text-decoration: none;
}

textarea:focus,
input:focus {
  outline: none;
  border: 1px solid $primary !important;
  border-radius: 5px;
}

.ͼ3 .cm-gutters {
  display: none !important;
}

.cm-theme {
  width: 100%;
}

.markdown-preview {
  min-width: 100%;
  min-height: 100%;
}

.cm-content,
.cm-scroller {
  overflow-x: hidden !important;
  white-space: pre-wrap !important;
}

.ͼ1 .cm-scroller {
  display: block !important;
}

.login-title {
  position: relative;
  animation: slideInFromRight 1s ease-in-out;
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
