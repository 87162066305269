.content-wrapper {
  background-color: $background-color;
  color: white;
  height: 100vh;
}

.feature-sidebar {
  background-color: rgb(25, 26, 35);
  width: 48px;
  border-right: 1px solid var(--sidebar-border-color, #2c2d3c);
  transition: opacity 0.05s ease-in-out 0s;
  padding: 30px 0px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  svg {
    font-size: 22px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    flex-direction: column;

    &:first-child {
      gap: 10px;
    }

    div {
      padding: 5px;
      border-radius: 10%;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgb(133, 134, 153);
      }
    }
  }
}

.project-sidebar {
  background-color: rgb(25, 26, 35);
  width: 270px;
  border-right: 1px solid var(--sidebar-border-color, #2c2d3c);
  transition: opacity 0.05s ease-in-out 0s;
  padding: 0px 30px 20px 30px;
  overflow-y: auto;
}
