.page-content {
  background-color: rgb(24, 25, 34);
  justify-content: center;
  overflow: auto;

  .note {
    position: relative;
    width: calc(100% - 120px);
    margin: 30px auto 24px;
    max-width: 950px;
    flex-shrink: 0;

    .note-header {
      margin-top: 30px;
      margin-bottom: 30px;

      textarea {
        font-size: 36px;
        width: 100%;
        resize: none;
        background-color: $background-color;
        border: 1px solid $background-color;
        color: $white;
        height: 45px;
        resize: none;
        overflow-y: hidden;
        padding: 5px;

        &:focus {
          outline: none;
          border-color: transparent !important;
        }
      }

      .br {
        border-top: 1px solid $white;
      }

      span {
        font-size: 13px;
        color: $font-color;
        display: flex;
        gap: 5px;
        align-items: center;

        &.markee {
          color: $white;
        }
      }
    }
  }

  .notes-list-header {
    padding-top: 30px;
    width: 100%;
    align-self: center;
    margin-bottom: 30px;
  }

  .notes-list {
    width: 100%;
    align-self: center;
    padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: flex;

    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-content: flex-start;

    .note-card {
      background: rgb(29, 30, 43);
      padding: 10px;
      width: 272px;
      height: 164px;
      margin: 10px;
      border-radius: 5px;

      color: white;

      flex-direction: column;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
        transition-duration: 0.1s;
        transition-property: box-shadow;
        background-color: rgb(31, 33, 48);
      }

      .icons div {
        height: 22px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #1f2130;
        border-radius: 5px;

        &:hover {
          background-color: rgb(38, 39, 59);
          border: 1px solid rgb(57, 58, 75);
          transition-duration: var(--speed-highlightFadeIn);
        }
      }
      a {
        color: $white;
        overflow: hidden;
      }

      h3 {
        margin-bottom: 5px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  .tasks-list {
    width: 90%;
    max-width: 945px;
    padding-top: 30px;

    .header {
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #2c2d3c;
      margin-bottom: 50px;

      svg {
        font-size: 25px;
      }
    }

    .body {
      flex-direction: column;
    }

    .add-task {
      cursor: pointer;
    }
  }

  .task-details {
    width: 100%;
    display: flex;

    .date-button {
      background: transparent;
      border: none;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }

    .task-content {
      padding-top: 30px;
      flex: 2;
      padding-left: 5%;
      padding-right: 5%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 5px;
        border-bottom: 1px solid #2c2d3c;

        h4 {
          font-size: 15px;
        }
      }
      .body {
        display: flex;
        margin-top: 30px;
        flex-direction: column;
        gap: 20px;

        textarea {
          background-color: rgb(24, 25, 34);
          padding: 10px;
        }

        textarea.task-description {
          min-height: 120px;
          border-radius: 5px;
          border: none;
          color: $white;
          font-family: $sans-serif;
          font-size: 12px;
          resize: none;
          height: auto;
          overflow: hidden;

          &:focus {
            border: none;
          }
        }

        .desc {
          color: $white;
          font-family: $sans-serif;
          font-size: 12px;
          margin-left: 30px;
        }

        .form {
          border: 1px solid $border-color;
          gap: 10px;
          border-radius: 5px;
        }

        .plain-content {
          border: 1px solid transparent;
        }

        textarea.task-title {
          border: none;
          color: white;
          font-size: 22px;
          height: 30px;
          font-family: $sans-serif;
          resize: none;
          height: auto;
          overflow: hidden;

          &:focus {
            border: none;
          }
        }
      }
    }

    @media screen and (max-width: 1300px) {
      .task-attributes {
        display: none;
      }
    }

    .task-attributes {
      border-left: 1px solid #2c2d3c;
      min-width: 320px;
      max-width: 480px;
      background-color: rgb(29, 30, 43);

      .row {
        margin: 20px;

        .attributes {
          .label {
            color: rgb(133, 134, 153);
            width: 90px;
          }

          div:last-child {
            flex: 1;
            color: $white;
            border: 1px solid transparent;
            padding: 2px;
          }
        }
      }

      .task-detail-header {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
      }
    }
  }
}
